import React, { useState, useRef, useEffect } from 'react';
import { Clock, Check, X } from 'lucide-react';
import {
    sendMessage,
    getChatHistory,
    getChatById,
    updateMessageAsRead,
    sendMessageWithAttachment
} from '../services/api';
import { SearchIcon } from '@heroicons/react/solid';
import { v4 as uuidv4 } from 'uuid';
import {
    addSignalRListener,
    getConnection,
    joinChat,
    leaveChat,
    removeSignalRListener
} from "../services/signalRService";
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

const ChatInterface = ({ selectedTemplate, onBack, initialMessages, phoneNumber, canSendMessages, isReadOnly, chatID }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [canSendMessage, setCanSendMessage] = useState(false);
    const messagesEndRef = useRef(null);
    const [chatId, setChatId] = useState(null);
    const [attachment, setAttachment] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const fileInputRef = useRef(null);


    const handleRemoveAttachment = () => {
        setAttachment(null);
        setPreviewUrl(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setAttachment(file);

        // Crea un'anteprima dell'immagine
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null);
        }
    };

    useEffect(() => {
        let isSubscribed = true;

        const initializeChat = async () => {
            let chatData;
            let newChatId;

            if (chatID) {
                try {
                    chatData = await getChatById(chatID);
                    newChatId = chatID;
                } catch (error) {
                    console.error('Errore nel caricamento della chat:', error);
                }
            } else if (phoneNumber) {
                try {
                    const history = await getChatHistory(phoneNumber);
                    if (history.length > 0) {
                        newChatId = history[0].chatId;
                        chatData = { messages: history };
                    }
                } catch (error) {
                    console.error('Errore nel caricamento della cronologia:', error);
                }
            }

            if (isSubscribed && chatData) {
                setMessages(chatData.messages);
                setCanSendMessage(!isReadOnly && chatData.messages.some(msg => msg.isFromCustomer));
                setChatId(newChatId);
            }

            return newChatId;
        };

        const loadMessages = async () => {
            let messagesData = [];
            if (chatID) {
                try {
                    const chatData = await getChatById(chatID);
                    messagesData = chatData.messages;
                    setChatId(chatID);
                } catch (error) {
                    console.error('Errore nel caricamento della chat:', error);
                }
            } else if (phoneNumber) {
                try {
                    const history = await getChatHistory(phoneNumber);
                    messagesData = history;
                    if (history.length > 0) {
                        setChatId(history[0].chatId);
                    }
                } catch (error) {
                    console.error('Errore nel caricamento della cronologia:', error);
                }
            }

            // Processa i messaggi per gestire gli allegati
            const processedMessages = messagesData.map(message => {
                if (message.attachmentPath) {
                    const decodedUrl = decodeURIComponent(message.attachmentPath);
                    return {
                        ...message,
                        content: (
                            <img
                                src={decodedUrl}
                                alt="Received image"
                                style={{ maxWidth: '200px', maxHeight: '200px', cursor: 'pointer' }}
                                onClick={() => window.open(decodedUrl, '_blank')}
                            />
                        )
                    };
                }
                return message;
            });

            setMessages(processedMessages);
            setCanSendMessage(!isReadOnly && processedMessages.some(msg => msg.isFromCustomer));

        };

        loadMessages();
        scrollToBottom();

        const setupSignalR = async (newChatId) => {
            console.log("Setting up SignalR, chatId:", newChatId);
            if (newChatId) {
                try {
                    const connection = await getConnection();
                    console.log("SignalR connection status:", connection.state);

                    await joinChat(newChatId);
                    console.log("Joined chat:", newChatId);

                    await addSignalRListener("ReceiveMessage", (message) => {
                        if (isSubscribed) {
                            console.log("Received message via SignalR:", message);
                            handleReceiveMessage(message);
                        }
                    });

                    // Aggiungi questo nuovo listener nel setupSignalR
                    await addSignalRListener("MessageStatusUpdate", (update) => {
                        if (isSubscribed) {
                            console.log("Received status update:", update);
                            setMessages(prevMessages => prevMessages.map(msg =>
                                // Cerca il messaggio sia per id che per content per maggiore robustezza
                                (  msg.id === update.messageId || msg.content === update.content) ? {
                                    ...msg,
                                    id: update.messageId, // Aggiorna con l'ID reale
                                    status: update.status
                                } : msg
                            ));
                        }
                    });

                    // Test the connection
                    await connection.invoke("TestConnection");
                } catch (error) {
                    console.error("Error setting up SignalR:", error);
                }
            } else {
                console.warn("ChatId not available, skipping SignalR setup");
            }
        };

        const initialize = async () => {
            let isSubscribed = true;
            const newChatId = await initializeChat();
            await loadMessages()
            //const newChatId = chatID || (messages.length > 0 ? messages[0].chatId : null);
            await setupSignalR(newChatId);

            scrollToBottom();
        };

        initialize();

        return () => {
            isSubscribed = false;
            if (chatId) {
                leaveChat(chatId);
                removeSignalRListener("ReceiveMessage", handleReceiveMessage);
            }
        };
    }, [chatID, phoneNumber, isReadOnly]);

    const handleReceiveMessage = async (message) => {
        const messageId = message.id !== 0 ? message.id : uuidv4();
        if (!canSendMessages){
            canSendMessages = true;
            isReadOnly = false;
        }

        // Gestisci i messaggi con immagini
        let content = message.content;
        if (message.attachmentPath) {
            const decodedUrl = decodeURIComponent(message.attachmentPath);
            content = (
                <img
                    src={decodedUrl}
                    alt="Received image"
                    style={{ maxWidth: '200px', maxHeight: '200px', cursor: 'pointer' }}
                    onClick={() => window.open(decodedUrl, '_blank')}
                />
            );
        }

        setMessages(prevMessages => [...prevMessages, {...message, id: messageId, content}]);
        if (message.isFromCustomer) {
            setCanSendMessage(true);
        }
        scrollToBottom();

        // Aggiorna il messaggio come letto nel backend
        try {
            await updateMessageAsRead(chatId, messageId);
        } catch (error) {
            console.error('Errore nell\'aggiornamento dello stato del messaggio:', error);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100); // Piccolo ritardo per assicurarsi che il DOM sia aggiornato
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "d MMMM yyyy, HH:mm", { locale: it });
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if ((!inputMessage.trim() && !attachment) || !canSendMessages || isReadOnly) return;

        const newMessage = {
            id: uuidv4(),
            content: inputMessage,
            isFromCustomer: false,
            timestamp: new Date().toISOString(),
            status: 'sending'
        };

        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputMessage('');

        try {
            let response;
            if (attachment) {
                // Converti il file in base64
                const base64 = await convertToBase64(attachment);

                response = await sendMessageWithAttachment({
                    destination: phoneNumber,
                    accountNumber: '2',
                    attachment: {
                        fileName: attachment.name,
                        contentType: attachment.type,
                        content: base64
                    },
                    content: inputMessage.trim() // Includi il testo se presente
                });
            } else {
                response = await sendMessage({
                    destination: phoneNumber,
                    accountNumber: '2',
                    content: inputMessage,
                });
            }

            console.log(response);

            // Aggiorna il messaggio esistente invece di aggiungerne uno nuovo
            setMessages(prevMessages => prevMessages.map(msg =>
                msg.id === response.messageId ? {
                    ...msg,
                    id: response.id,
                    status: 'sent',
                    content: attachment ? (
                        <img
                            src={URL.createObjectURL(attachment)}
                            alt="Sent image"
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                    ) : inputMessage
                } : msg
            ));
        } catch (error) {
            console.error('Errore nell\'invio del messaggio:', error);
            setMessages(prevMessages => prevMessages.map(msg =>
                msg.id === newMessage.id ? {...msg, status: 'error'} : msg
            ));
        }

        setAttachment(null);
        setPreviewUrl(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const filteredMessages = messages.filter(message => {
        if (typeof message.content === 'string') {
            return message.content.toLowerCase().includes(searchTerm.toLowerCase());
        } else if (message.attachmentPath) {
            // Se è un'immagine, mostriamola sempre nel filtro
            return true;
        } else {
            // Se non è né una stringa né un'immagine, non la includiamo nel filtro
            return false;
        }
    });

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };

    return (
        <div className="flex flex-col h-full bg-[#efeae2]">
            {/* Header */}
            <div className="bg-[#f0f2f5] py-2 px-4 flex items-center">
                <div className="w-10 h-10 bg-gray-300 rounded-full mr-3 flex-shrink-0"></div>
                <div className="flex-grow">
                    <h2 className="font-semibold text-gray-800">{phoneNumber}</h2>
                </div>
                {isSearching ? (
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="border rounded px-2 py-1"
                        placeholder="Cerca messaggi..."
                    />
                ) : (
                    <button onClick={() => setIsSearching(true)}>
                        <SearchIcon className="h-6 w-6 text-gray-600"/>
                    </button>
                )}
            </div>

            {/* Chat messages */}
            <div
                className="flex-1 overflow-y-auto p-4 space-y-2"
                style={{
                    backgroundImage: `url("/whatsapp-background.png")`,
                    backgroundSize: "contain",
                    backgroundRepeat: 'repeat',
                }}
            >
                {filteredMessages.map((message) => (
                    <div
                        key={message.id}
                        className={`flex ${
                            message.isFromCustomer ? 'justify-start' : 'justify-end'
                        }`}
                    >
                        <div
                            className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
                                message.isFromCustomer
                                    ? 'bg-white text-black'
                                    : 'bg-[#d9fdd3] text-black'
                            }`}
                        >
                            {typeof message.content === 'string' ? (
                                <p>{message.content}</p>
                            ) : message.attachmentPath ? (
                                <img
                                    src={decodeURIComponent(message.attachmentPath)}
                                    alt="Received image"
                                    style={{ maxWidth: '200px', maxHeight: '200px', cursor: 'pointer' }}
                                    onClick={() => window.open(decodeURIComponent(message.attachmentPath), '_blank')}
                                />
                            ) : (
                                message.content
                            )}
                            <div className="text-xs text-gray-500 float-right mt-1 flex items-center">
                                <span>{formatDate(message.timestamp)}</span>
                                {!message.isFromCustomer && (
                                    <span className="ml-1">
                                        {message.status === 'Sending'   && <Clock className="w-3 h-3" />}
                                        {message.status === 'Sent' || message.status === 'Submitted' && <Check className="w-3 h-3" />}
                                        {message.status === 'Delivered' && (
                                            <div className="flex -space-x-1">
                                                <Check className="w-3 h-3" />
                                                <Check className="w-3 h-3" />
                                            </div>
                                        )}
                                        {message.status === 'Read' && (
                                            <div className="flex -space-x-1">
                                                <Check className="w-3 h-3 text-blue-500" />
                                                <Check className="w-3 h-3 text-blue-500" />
                                            </div>
                                        )}
                                        {message.status === 'Failed' && <X className="w-3 h-3 text-red-500" />}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef}/>
            </div>

            {/* Input area */}
            <form onSubmit={handleSendMessage} className="bg-[#f0f2f5] p-2">
                <div className="flex items-center bg-white rounded-full">
                    <input
                        type="text"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        className="flex-1 bg-transparent text-black border-none rounded-l-full py-2 px-4 focus:outline-none"
                        placeholder={isReadOnly ? "Chat in sola lettura" : "Scrivi un messaggio..."}
                        disabled={!canSendMessages || isReadOnly}
                    />
                    {/*<input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="hidden"
                        accept="image/*,application/pdf"
                    />
                    <button
                        type="button"
                        onClick={() => fileInputRef.current.click()}
                        className="text-gray-500 hover:text-gray-700 px-2"
                    >
                        📎
                    </button>*/}
                    <button
                        type="submit"
                        className={`${(canSendMessages && !isReadOnly && (inputMessage.trim() || attachment)) ? 'bg-[#00a884] hover:bg-[#02735e]' : 'bg-gray-400 cursor-not-allowed'} text-white rounded-full p-2 mx-1`}
                        disabled={!canSendMessages || isReadOnly || (!inputMessage.trim() && !attachment)}
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                        </svg>
                    </button>
                </div>
                {previewUrl && (
                    <div className="mt-2 relative">
                        <img src={previewUrl} alt="Preview" style={{maxWidth: '200px', maxHeight: '200px'}}/>
                        <button
                            type="button"
                            onClick={handleRemoveAttachment}
                            className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 m-1 hover:bg-red-600"
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default ChatInterface;